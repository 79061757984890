import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login/login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    // name: 'home',
    redirect: '/login', // 默认跳转到登录页
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    children:[
      {
        path: '/Dialogue',
        name: 'signage',
        component: () => import(/* webpackChunkName: "about" */ '../views/Dialogue/index.vue'),
        meta: { title:"AI对话" }
      },
    ]
 
  },
  
    {
      path: '/about',
      name: 'about',
      redirect: '/science', 
      meta: { title:"AI学术" },
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      children:[
        {
          path: '/science',
          name: 'dataAcquisition',
          component: () => import(/* webpackChunkName: "about" */ '../views/science/index.vue'),
          meta: { title:"AI学术" }
        },
        {
          path: '/science1',
          name: 'dataAcquisition',
          component: () => import(/* webpackChunkName: "about" */ '../views/science/index1.vue'),
          meta: { title:"AI学术" }
        },
        {
          path: '/grzx',
          name: 'dataAcquisition',
          component: () => import(/* webpackChunkName: "about" */ '../views/grzx/grzx.vue'),
          meta: { title:"个人中心" }
        },
        {
          path: '/ljxf',
          name: 'dataAcquisition',
          component: () => import(/* webpackChunkName: "about" */ '../views/grzx/ljxf.vue'),
          meta: { title:"立即续费" }
        },

    ]
  },
{
  path: '/about',
  name: 'about',
  redirect: '/writing',
  // 数据报表 
  meta: { title:"" },
  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  children:[
    {
      path: '/writing',
      name: 'dataReport',
      component: () => import(/* webpackChunkName: "about" */ '../views/writing/index.vue'),
      meta: { title:"A写作" }
    },
    {
      path: '/writing1',
      name: 'dataReport',
      component: () => import(/* webpackChunkName: "about" */ '../views/writing/index1.vue'),
      meta: { title:"A写作" }
    },
]
},
{
  path: '/about',
  name: 'about',
  redirect: '/message', 
  meta: { title:"" },
  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  children:[
    {
      path: '/message',
      name: 'user',
      component: () => import(/* webpackChunkName: "about" */ '../views/system/message.vue'),
      meta: { title:"MJ绘图" }
    },
    {
      path: '/draw',
      name: 'role',
      component: () => import(/* webpackChunkName: "about" */ '../views/system/draw.vue'),
      meta: { title:"AI资讯" }
    },
]
},
    
    
]


const router = new VueRouter({
  routes
})

export default router
