import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";

// 创建axios实例
const service = axios.create({
  baseURL: "/",
  timeout: 2000000,
  headers: {
    // "Cache-Control": "no-cache",
    "Content-Type": "application/json; charset=utf-8",
  },
});
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    // console.log(localStorage.getItem("token"))
    const isToken = (config.headers || {}).isToken === false;
    // console.log(localStorage.getItem("token"))
    // localStorage.getItem("token")
    if (localStorage.getItem("token") && !isToken) {
      config.headers["Authorization"] = localStorage.getItem("token"); // 让每个请求携带自定义token 请根据实际情况自行修改

      //console.log(config.headers["token"])
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    if (config.method === "post" && config.data && config.data.qs) {
      const pms = new FormData();
      for (let a in config.data) {
        pms.append(a, config.data[a]); //id直接从data中一个个拿
      }
      config.data = pms;
    }

    return config;
  },
  (error) => {
    // console.log(error)
    Promise.reject(error);
  }
);

// response拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(response)
    // if (response.data.code == "401") {
    //   /* window.location.href = "/" */
    //   router.replace({ path: "/Login" })

    //   Message({
    //     message: response.msg,
    //     type: "error",
    //   });
    // }
    const res = response.data;
    /*     console.log(res.code) */
    if (res.code == 501) {
      Message({
        message: res.msg,
        type: "error",
      });
      router.replace({ path: "/Login" }).catch((err) => err);
      localStorage.removeItem("user_id");
    }
    

    // if(res.code == 500){
    //   return;
    // }
    // if(res.code == 400){
    //   return;
    // }
    /*     console.log(res.code) */
    if (res.code == 401) {
      localStorage.removeItem("user_id");
      let getState = sessionStorage.getItem("state");
      // console.log(getState,'状态值');
      if (!getState) {
        console.log(getState, "状态值");
        Message({
          message: res.message,
          type: "warning",
        });
        sessionStorage.setItem("state", true);
      }
      setTimeout(function () {
        sessionStorage.removeItem("state");
      }, 5000);

      router.replace({ path: "/login" }).catch((err) => err);
    }
    return res;
  },

  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);

// 用于文件上传（不需要Qs.stringify,直接添加form-data就行）
const filereq = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Cache-Control": "no-cache",
  },
});

// request拦截器
filereq.interceptors.request.use(
  (config) => {
    // if (localStorage.getItem("token_s")) {
    //   let token = JSON.parse(localStorage.getItem("token_s"))
    //   config.headers.common["Authorization"] = "Bearer " + token
    //   // config.headers.common["userid"] = token.userid
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response拦截器
filereq.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res && res.a !== "1" && res.a) {
      Message({
        message: res.msg,
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);

const down = axios.create({
  baseURL: "/",
  timeout: 2000000,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json;",
  },
  responseType: "blob", //重要
});
// 是否需要设置 token

down.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;
    console.log(localStorage.getItem("user_id"));
    if (localStorage.getItem("user_id") && !isToken) {
      config.headers["userId"] = localStorage.getItem("user_id"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    /*     console.log(error) */
    Promise.reject(error);
  }
);
down.interceptors.response.use(
  (response) => {
    let filmz = localStorage.getItem("fileName");
    let filgeshi = localStorage.getItem("filegeshi");
    // console.log(filmz, 115)
    const blob = new Blob([response.data]); //处理文档流
    let fileName = null;
    if (filgeshi) {
      fileName = filmz + filgeshi; //文件名
    } else {
      fileName = filmz + ".xlsx"; //文件名
    }
    /*    const fileName = new Date().getTime() + ".xlsx"//文件名  */
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);
const downExe = axios.create({
  baseURL: "/",
  timeout: 2000000,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json; charset=utf-8",
  },
  responseType: "blob", //重要
});
// 是否需要设置 token

downExe.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;

    if (localStorage.getItem("user_id") && !isToken) {
      config.headers["userId"] = localStorage.getItem("user_id"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },
  (error) => {
    /*     console.log(error) */
    Promise.reject(error);
  }
);

downExe.interceptors.response.use(
  (response) => {
    /*     console.log(response, 1233) */
    const blob = new Blob([response.data]); //处理文档流
    const fileName = new Date().getTime() + ".xlsx"; //文件名
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
);

export { service, filereq, down, downExe };