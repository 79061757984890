<template>
  <div class="auth-container">
    <div class="header">
      <img
        src="../../assets/dl.png"
        style="width: 15px; height: 15px; margin-right: 5px"
      />
      <span class="dl" v-if="token == ''" @click="dengl">登录</span>
      <span class="zc" v-if="token == ''" @click="zhuce">注册</span>
      <el-dropdown v-else>
        <span style="cursor: pointer">{{ userinfo.phone }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout()">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="bigBox">
      <div class="bigBoxTop">
        <img src="../../assets/openAi.png" class="bigBoximg" />
      </div>
      <div class="bigBoxmoddel">
        <img src="../../assets/aiHb.png" class="bixBoxMoImg" />
        <img src="../../assets/aiCp.png" class="bixBoxMoImg" />
        <img src="../../assets/aiZh.png" class="bixBoxMoImg" @click="aizh" />
        <img src="../../assets/aiWa.png" class="bixBoxMoImg" @click="aiwa" />
      </div>
      <div class="bigBoxFotter">
        <el-input style="height: 55px" placeholder="登陆后即可免费体验">
          <!-- <div  class="suffix1"><img src="../../assets/inputLeft.png" alt=""></div> -->
          <template #suffix>
            <div class="suffix">
              <img
                src="../../assets/bc.png"
                style="margin-right: 10px; position: relative; top: -5px"
                alt=""
                @click="aiwa"
              />
            </div>
            <div class="suffix">
              <img
                src="../../assets/fs.png"
                @click="aiwa"
                style="margin-right: 15px; width: 55px; margin-top: 5px"
                alt=""
              />
            </div>
          </template>
        </el-input>
      </div>
    </div>
    <div class="footer">
      <span>备案号</span>
    </div>
    <!-- <el-form-item prop="yzm">
                  <el-input
                    type="text"
                    style="padding-left: 0px"
                    v-model="loginform.yzm"
                    placeholder="请输入图形验证码"
                    autocomplete="off"
                  >
                    <img
                      slot="prefix"
                      src="../../assets/mm.png"
                      alt=""
                      style="
                            width: 64%;
                            height: 34%;
                            margin-left: 11px;
                            margin-top: 17px;
                      "
                    />
                  </el-input>
                  <input type="button" @click="createdCode" class="verification" v-model="checkCode" />
                </el-form-item> -->
    <el-dialog
      title=""
      width="28%"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="zhdl">
        <div class="smdl" v-if="indexI == 1" style="margin-right: 28px">
          账号密码登录
        </div>
        <div
          class="smdl"
          v-if="indexI == 1"
          style="color: #1a51e1; margin-left: 23px"
          @click="smdlClic"
        >
          扫码登录
        </div>
        <div class="smdl" v-if="indexI == 2">使用微信APP扫码登录</div>
        <div
          class="smdl"
          v-if="indexI == 2"
          style="color: #1a51e1; margin-right: 28px"
          @click="zhmcdl"
        >
          手机号登录
        </div>
      </div>
      <div v-if="indexI == 1">
        <div>
          <el-form
            :model="loginform"
            ref="loginform"
            class="demo-loginform"
            style="margin-top: 30px"
          >
            <el-form-item prop="username">
              <div>
                <el-input
                  type="text"
                  style="padding-left: 0px"
                  v-model="loginform.tel"
                  placeholder="请输入手机号"
                  autocomplete="off"
                  class="yhmmm"
                >
                  <template slot="append">
                    <el-button v-if="show" type="primary" @click="hqyzm"
                      >获取验证码</el-button
                    >
                    <el-button v-else disabled>
                      重新发送({{ count }}s)</el-button
                    >
                  </template>
                </el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <div>
                <el-input
                  placeholder="请输入手机验证码"
                  style="padding-left: 0px"
                  v-model="loginform.code"
                  class="yhmmm"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            style="
              width: 100%;
              margin-top: 10px;
              border: 0px;
              border-radius: 15px;
              width: 100%;
              margin-top: 10px;
              border: 0px;
              border-radius: 100px;
              height: 55px;
              line-height: 13px;
              margin-top: 8px;
            "
            @click="login()"
            >登录</el-button
          >
        </div>
        <el-divider
          ><span style="font-size: 16px">其他登录方式</span></el-divider
        >
        <div class="qtdlfs">
          <img
            :src="item.url"
            style="width: 40px; margin-left: 10px; cursor: pointer"
            v-for="(item, index) in qtdlList"
            :key="index"
          />
        </div>
      </div>
      <div v-else>
        <div class="xy">
          <span>登录即同意</span
          ><span style="color: #467bff">《用户协议》</span>和<span
            style="color: #467bff"
            >《隐私条款》</span
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <!-- 注册 -->
    <el-dialog
      title=""
      width="28%"
      :visible.sync="dialogVisibleZc"
      :before-close="handleClose"
    >
      <div class="zhdlA">
        <div class="smdl" style="margin-bottom: 10px" @click="smdlClic">
          用户注册
        </div>
        <div class="qxzsf">
          <span style="color: #1a51e1; margin-top: 10px">请选择您的身份</span>
        </div>
      </div>
      <div class="rysf">
        <div
          :class="indexGrxx == item.type ? 'xzrysf' : 'xzrysfWxz'"
          @click="grxxName(item, index)"
          v-for="(item, index) in yhxxList"
          :key="item.type"
        >
          <div>
            <span class="cxyxx">{{ item.name }}</span>
          </div>
          <div>
            <img :src="item.url" style="width: 110px; margin-top: 9px" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="
            border-radius: 35px;
            padding: 12px 30px;
            background: #467bff;
            color: #fff;
          "
          @click="xyb()"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      width="28%"
      :visible.sync="dialogVisibleXyb"
      :before-close="handleClose"
    >
      <div class="zhdlA">
        <div class="smdl" style="margin-bottom: 10px" @click="smdlClic">
          用户注册
        </div>
        <div class="qxzsf">
          <span style="color: #1a51e1; margin-top: 10px">个人基本信息</span>
        </div>
      </div>
      <div style="margin-top: 15px">
        <el-form ref="form" :model="form" label-width="0px">
          <el-form-item label="">
            <el-input
              class="inputSty"
              v-model="form.nickname"
              placeholder="昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <!-- <el-input
              class="inputSty"
              v-model="form.name"
              placeholder="性别"
            ></el-input> -->
            <el-select
              class="inputSty"
              style="width: 100%"
              v-model="form.gender"
              placeholder="性别"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <!-- <el-input
              class="inputSty"
              v-model="form.name"
              placeholder=""
            ></el-input> -->
            <el-date-picker
              class="inputSty"
              style="width: 100%"
              v-model="form.birth_date"
              type="date"
              placeholder="出生年月"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <!-- <el-input
              class="inputSty"
              v-model="form.tel"
              placeholder="请输入手机号"
            ></el-input> -->
            <el-input
              type="text"
              style="padding-left: 0px"
              v-model="form.tel"
              placeholder="手机号"
              autocomplete="off"
              class="yhmmm"
            >
              <template slot="append">
                <el-button v-if="isshow" type="primary" @click="zcHqyzm"
                  >获取验证码</el-button
                >
                <el-button v-else disabled>
                  重新发送({{ countTime }}s)</el-button
                >
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              class="inputSty"
              v-model="form.code"
              placeholder="验证码"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              class="inputSty"
              v-model="form.invite_code"
              placeholder="邀请码（选填）"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="
            border-radius: 35px;
            padding: 12px 30px;
            background: #467bff;
            color: #fff;
          "
          @click="saveWc"
          >注册</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title=""
      width="28%"
      :visible.sync="dialogVisibleZcwc"
      :before-close="handleClose"
    >
      <div
        style="
          text-align: center;
          font-size: 36px;
          color: #86fa58;
          letter-spacing: 3px;
        "
      >
        注册成功
      </div>
      <div style="text-align: center; font-size: 14px; padding: 20px 0">
        恭喜您成为本平台的免费会员。免费会员享受，如下（示例）：
      </div>

      <div style="padding: 0 0 20px">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="功能" align="center">
          </el-table-column>
          <el-table-column prop="name" label="线路" align="center">
          </el-table-column>
          <el-table-column prop="address" label="次数" align="center">
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center">
        <el-button size="small" type="primary" @click="goLogin"
          >去登录</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<style scoped>
.bigBoxmoddel img:hover {
  transform: translateY(-20px);
}
.bigBoxmoddel img {
  transition: transform 0.3s ease-in-out;
  /* box-shadow: 0px 0px 8px 6px rgba(0, 0, 0,0.2); */
}
</style>
<script>
import { getLogin } from "@/api/login/login.js";
import { service } from "@/assets/js/request";
export default {
  data() {
    return {
      indexGrxx: "programmer",
      yhxxList: [
        //用户身份可选 programmer, student, others
        {
          type: "programmer",
          name: "程序员",
          url: require("../../assets/A.png"),
        },
        {
          type: "student",
          name: "学生",
          url: require("../../assets/B.png"),
        },
        {
          type: "others",
          name: "其他",
          url: require("../../assets/C.png"),
        },
      ],
      dialogVisible: false,
      dialogVisibleZcwc: false,
      form: {},
      userinfo: {},
      title: "获取验证码",
      show: true,
      isshow: true,
      loginform: {},
      count: "",
      countTime: "",
      timer: null,
      timerOut: null,
      token: "",
      indexI: 1,
      dialogVisibleZc: false,
      dialogVisibleXyb: false,
      options: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      qtdlList: [
        {
          url: require("../../assets/wx.png"),
        },
        {
          url: require("../../assets/dy.png"),
        },
        {
          url: require("../../assets/xl.png"),
        },
        {
          url: require("../../assets/zfb.png"),
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.createdCode();
  },
  mounted() {
    if (window.localStorage.getItem("token")) {
      this.token = window.localStorage.getItem("token");
    } else {
      this.token = "";
    }

    if (window.localStorage.getItem("userinfo")) {
      this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
      this.userinfo.phone = window.localStorage.getItem("phone");
    } else {
      this.userinfo = {};
    }

    console.log(this.token);
  },
  methods: {
    goLogin() {
      this.dialogVisibleZcwc = false;
      this.dialogVisible = true;
    },
    zcHqyzm() {
      if (!this.form.tel) {
        this.$message.warning("请输入手机号");
      } else if (this.isValidPhoneNumber(this.form.tel) == false) {
        this.$message.warning("请输入正确的手机号");
      } else {
        service({
          url: `/api/smsForCode`,
          method: "post",
          data: {
            tel: this.form.tel,
          },
          onDownloadProgress: (e) => {
            console.log(e, "e");
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message.success("验证码已发送，请查收");
            let Time_Count = 60;
            if (!this.timerOut) {
              this.countTime = Time_Count;
              this.isshow = false;
              this.timerOut = setInterval(() => {
                if (this.countTime > 0 && this.countTime <= Time_Count) {
                  this.countTime--;
                } else {
                  this.isshow = true;
                  clearInterval(this.timerOut);
                  this.timerOut = null;
                }
              }, 1000);
            }
          } else {
            this.$message.warning(res.message);
          }
        });
      }
    },
    isValidPhoneNumber(phoneNumber) {
      const regExp = /^1[0-9]{10}$/;
      return regExp.test(phoneNumber);
    },
    hqyzm() {
      if (!this.loginform.tel) {
        this.$message.warning("请输入手机号");
      } else if (this.isValidPhoneNumber(this.loginform.tel) == false) {
        this.$message.warning("请输入正确的手机号");
      } else {
        service({
          url: `/api/smsForCode`,
          method: "post",
          data: {
            tel: this.loginform.tel,
          },
          onDownloadProgress: (e) => {
            console.log(e, "e");
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message.success("验证码已发送，请查收");
            let Time_Count = 60;
            if (!this.timer) {
              this.count = Time_Count;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= Time_Count) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message.warning(res.message);
          }
        });
      }
    },
    login() {
      console.log(this.loginform.tel);
      if (!this.loginform.tel || !this.loginform.code) {
        this.$message.warning("请输入手机号和验证码");
      } else {
        service({
          url: `/api/loginByPhone`,
          method: "post",
          data: {
            tel: this.loginform.tel,
            code: this.loginform.code,
          },
          onDownloadProgress: (e) => {
            console.log(e, "e");
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message.success("登录成功");
            window.localStorage.setItem("phone", this.loginform.tel);
            window.localStorage.setItem("token", res.data.userToken.token);
            window.localStorage.setItem(
              "userinfo",
              JSON.stringify(res.data.userToken)
            );
            this.token = window.localStorage.getItem("token");
            this.userinfo = res.data.userToken;
            this.userinfo.phone = window.localStorage.getItem("phone");
            console.log(this.userinfo.phone);
            this.dialogVisible = false;
            //  this.$router.push("/Dialogue");
          } else {
            this.$message.warning(res.message);
          }
        });
      }
    },
    aizh() {
      if (window.localStorage.getItem("token")) {
        this.$router.push("/draw");
      } else {
        this.dialogVisible = true;
      }
    },
    logout() {
      this.$message.success("退出成功");
      window.localStorage.clear();
      this.userinfo = {};
      this.token = "";
    },
    aiwa() {
      if (window.localStorage.getItem("token")) {
        this.$router.push("/Dialogue");
      } else {
        this.dialogVisible = true;
      }
    },
    xyb() {
      this.dialogVisibleZc = false;
      this.dialogVisibleXyb = true;
    },
    saveWc() {
      if (!this.form.nickname) {
        this.$message.warning("请输入昵称!");
        return;
      }
      if (!this.form.gender) {
        this.$message.warning("请选择性别！");
        return;
      }
      if (!this.form.birth_date) {
        this.$message.warning("请选择出生年月!");
        return;
      }
      if (!this.form.tel) {
        this.$message.warning("请输入手机号~");
        return;
      }
      if (!this.form.code) {
        this.$message.warning("请输入验证码~");
        return;
      }
      service({
        url: `/api/register`,
        method: "post",
        data: {
          ...this.form,
          role: this.indexGrxx,
        },
        onDownloadProgress: (e) => {
          console.log(e, "e");
        },
      }).then((res) => {
        if (res.code == 0) {
          this.dialogVisibleXyb = false;
          this.dialogVisibleZcwc = true;
        } else {
          this.$message.warning(res.message);
        }
      });
      // this.dialogVisibleXyb = false;
    },
    grxxName(item, index) {
      this.indexGrxx = item.type;
    },
    smdlClic() {
      this.indexI = 2;
    },
    zhmcdl() {
      this.indexI = 1;
    },
    dengl() {
      this.dialogVisible = true;
    },
    zhuce() {
      this.$message.warning("暂未开放，请耐心等待~");
      return;
      this.dialogVisibleZc = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleZc = false;
      this.dialogVisibleXyb = false;
      this.dialogVisibleZcwc = false;
    },
    // 图片验证码
    createdCode() {
      // 先清空验证码输入
      this.code = "";
      this.checkCode = "";
      this.picLyanzhengma = "";
      // 验证码长度
      const codeLength = 4;
      // 随机数
      const random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
      );
      for (let i = 0; i < codeLength; i++) {
        // 取得随机数的索引(0~35)
        let index = Math.floor(Math.random() * 36);
        // 根据索引取得随机数加到code上
        this.code += random[index];
      }
      // 把code值赋给验证码
      console.log(this.code);
      this.checkCode = this.code;
    },
    // login() {
    //   this.$router.push("/Dialogue");
    // },
  },
};
</script>

<style scoped>
/deep/ .yhmmm .el-input__inner {
  font-size: 16px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 55px;
  line-height: 40px;
  outline: 0;
  padding: 0 65px 0 25px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.rysf {
  width: 100%;
}
.xzrysf {
  width: 100%;
  height: 134px;
  border: 1px solid rgba(26, 81, 225, 0.6);
  background: #eaf0ff;
  border-radius: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #1a51e1;
}
.xzrysfWxz {
  width: 100%;
  height: 134px;
  border: 1px solid #e2e2e2;
  background: linear-gradient(130deg, rgba(226, 226, 226, 0) 51%, #e2e2e2);
  border-radius: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  color: #333333;
}
@font-face {
  font-family: "PingFang SC, PingFang SC-Semibold";
  src: url("../../assets/PingFangSCPingFangSCSemibold.ttf");
}
.cxyxx {
  font-size: 26px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  text-align: center;
  /* color: #1a51e1; */
  line-height: 22px;
}
@font-face {
  font-family: "PingFang SC, PingFang SC-Regular";
  src: url("../../assets/PingFangSCPingFangSCSemibold.ttf");
}
.qxzsf {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #1a51e1;
  line-height: 22px;
}
.auth-container {
  /* background: url("@/assets/imgs/bjt.png") no-repeat center center; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
}
.header {
  width: 99%;
  height: 60px;
  line-height: 60px;
  text-align: right;
}
.zc {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}
.dl {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
}

.bigBox {
  width: 67%;
  height: 400px;
  /* height: 2.5rem; */
}
.bigBoximg {
  width: 290px;
  height: 90px;
}
.bigBoxTop {
  text-align: center;
}
.bigBoxmoddel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 5px;
}
.bixBoxMoImg {
  width: 23%;
  height: 150px;
  cursor: pointer;
}
.bigBoxFotter {
  margin-top: 25px;
}

.footer {
  width: 99%;
  height: 60px;
  text-align: center;
  color: #141414;
  text-align: center;
  font-size: 24px;
  opacity: 0.32;
  font-weight: 400;
  line-height: 60px;
}
.suffix {
  display: inline-block;
  /* position: relative;
    top: 0.07rem; */
}
.suffix1 {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
.smdl {
  font-size: 20px;
  cursor: pointer;
}
.zhdl {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.zhdlA {
  /* float: left; */
  /* margin:10px 0 0 10px; */
}
.inputSm {
  border: 0px;
  border-bottom: 1px solid;
  border-radius: 0;
}
/deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 65px;
  line-height: 40px;
  outline: 0;
  padding: 0 65px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .sjh .el-input__inner {
  font-size: 16px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 55px;
  line-height: 40px;
  outline: 0;
  padding: 0 65px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.qtdlfs {
  text-align: center;
}
.xy {
  text-align: center;
  font-size: 16px;
}
/deep/ .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center;
  box-sizing: border-box;
}
/deep/ .inputSty .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 20px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
/deep/ .inputSty .el-input__icon {
  display: none !important;
}
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 50%;
}
/deep/ .el-dialog__body {
  padding: 30px 35px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
</style>
